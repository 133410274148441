import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	private dialogContainer!: HTMLElement;

	constructor(private injector: Injector, private appRef: ApplicationRef, private resolver: ComponentFactoryResolver) {}

	open<T, R>(component: Type<T>, data?: any): Observable<R> {
		const subject = new Subject<R>();

		const factory = this.resolver.resolveComponentFactory(component);
		const componentRef = factory.create(this.injector);

		if (data) {
			Object.assign(componentRef.instance, data);
		}

		(componentRef.instance as any).close = (result?: R) => {
			console.log('result', result);

			subject.next(result);
			subject.complete();
			this.close(componentRef);
		};

		this.appRef.attachView(componentRef.hostView);
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		let dialogRef;

		this.dialogContainer = document.createElement('div');
		this.dialogContainer.classList.add('dialog-background');
		dialogRef = document.createElement('div');
		dialogRef.classList.add('app-dialog');

		dialogRef.appendChild(domElem);
		this.dialogContainer.appendChild(dialogRef);

		this.dialogContainer.addEventListener('click', (event) => {
			if (event.target === this.dialogContainer) {
				subject.complete();
				this.close(componentRef);
			}
		});

		document.body.appendChild(this.dialogContainer);

		return subject.asObservable();
	}

	openAtBody<T, R>(component: Type<T>, data?: any): Observable<R> {
		const subject = new Subject<R>();

		const factory = this.resolver.resolveComponentFactory(component);
		const componentRef = factory.create(this.injector);

		if (data) {
			Object.assign(componentRef.instance, data);
		}

		(componentRef.instance as any).close = (result?: R) => {
			subject.next(result);
			subject.complete();
			this.close(componentRef);
		};

		this.appRef.attachView(componentRef.hostView);
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

		document.body.appendChild(domElem);

		return subject.asObservable();
	}

	private close<T>(componentRef: any): void {
		this.appRef.detachView(componentRef.hostView);
		componentRef.destroy();
		if (this.dialogContainer) {
			this.dialogContainer.remove();
			this.dialogContainer = undefined!;
		}
	}
}
